import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 29.362616,  // Latitude of the location
  lng: 47.9645771, // Longitude of the location
};

const Map = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true); // Set isLoaded to true after the component mounts
  }, []);

  return (
    <div>
      {isLoaded ? (
        <LoadScript googleMapsApiKey="AIzaSyDWRyF4pnYMA2qYcRmihClbqGRRqlEGsy4">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      ) : (
        <p>Loading Map...</p>
      )}
    </div>
  );
};

export default Map;